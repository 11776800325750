<app-modal-history
  [identifierGroupId]="policyUpdate?.identifiers?.identifierGroupId"
  [userId]="policyUpdate?.enrollment?.agency?.id"
  #modal
  (dismissed)="modalDismissed()">
  @if (policyUpdate) {
    <h3>Policy Update</h3>

    <div class="grid gap-2 grid-cols-2">
      <div class="card bg-primary text-white">
        <div class="card-body">
          <div class="card-title">
            @if (policyUpdate.commission) {
              <fa-icon [icon]="faCircleCheck"></fa-icon>
            } @else {
              <fa-icon [icon]="faCircleXmark"></fa-icon>
            }
            Commission
          </div>
          @if (policyUpdate.commission) {
            <div class="card-actions">
              <button
                class="btn bg-white text-primary btn-sm"
                type="button"
                (click)="showCommission(policyUpdate.commission)">
                <fa-icon [icon]="faEye"></fa-icon>
                Show
              </button>
            </div>
          }
        </div>
      </div>
      <div class="card bg-primary text-white">
        <div class="card-body">
          <div class="card-title">
            @if (policyUpdate.enrollment) {
              <fa-icon [icon]="faCircleCheck"></fa-icon>
            } @else {
              <fa-icon [icon]="faCircleXmark"></fa-icon>
            }
            Enrollment
          </div>
          @if (policyUpdate.enrollment) {
            <div class="card-actions">
              <button
                class="btn bg-white text-primary btn-sm"
                type="button"
                (click)="showEnrollment(policyUpdate.enrollment)">
                <fa-icon [icon]="faEye"></fa-icon>
                Show
              </button>
            </div>
          }
        </div>
      </div>
    </div>

    <div>
      <table class="table">
        <tbody>
          <tr>
            <td>Id</td>
            <td>{{ policyUpdate.id }}</td>
          </tr>
          <tr>
            <td>Created at</td>
            <td>{{ policyUpdate.createdAt?.timestamp | date }}</td>
          </tr>
          <tr>
            <td>Group Id</td>
            <td>
              {{ policyUpdate.identifiers?.identifierGroupId }}
            </td>
          </tr>
          @for (d of policyUpdate.identifiers?.bySource | keyvalue; track d) {
            <tr>
              <td>Identifier: {{ d.key }}</td>
              <td>
                <div class="flex gap-2 flex-wrap">
                  @for (i of d.value.ids; track i) {
                    <div class="badge badge-primary badge-outline">{{ i }}</div>
                  }
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    @if (
      !isDemo && (!production || authService.authUserType === UserType.AGENCY)
    ) {
      <div class="form-control">
        <label class="label cursor-pointer gap-2 justify-start">
          <input
            type="checkbox"
            class="checkbox"
            [ngModel]="showAdvanced"
            (ngModelChange)="toggleShowAdvanced()" />
          <span class="label-text">Show Advanced Data</span>
        </label>
      </div>
    }

    @if (showAdvanced) {
      <button
        class="btn btn-outline btn-sm"
        type="button"
        (click)="retry(policyUpdate)">
        Retry processing
      </button>

      <div class="mockup-code">
        <pre><code class="select-all">{{
            PolicyUpdate.toJSON(policyUpdate) | json
          }}</code></pre>
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
</app-modal-history>
