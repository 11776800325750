<ng-container *ngIf="keys">
  <div class="card" *ngIf="data$ | async; let state">
    <div class="card-body" *ngIf="state.policyUpdates; let data">
      <!-- <div *ngIf="agents.error">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        <h3 class="my-4">An error occurred</h3>
        <p>{{ agents.error }}</p>
      </div> -->

      <div class="overflow-x-auto" *ngIf="data && data.length > 0">
        <table class="table table-sm table-pin-cols">
          <thead>
            <tr>
              <th>Created at</th>
              <td>Group Id</td>
              <td>Commission</td>
              <td>Enrollment</td>
              <td>Statement Date</td>
              <td>Status</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let d of data">
              <tr>
                <th>
                  {{ d.createdAt?.timestamp | date }}
                </th>
                <td>{{ d?.identifiers?.identifierGroupId }}</td>
                <td>
                  @if (d?.commission) {
                    <fa-icon [icon]="faCircleCheck"></fa-icon>
                  } @else {
                    <fa-icon [icon]="faCircleXmark"></fa-icon>
                  }
                </td>
                <td>
                  @if (d?.enrollment) {
                    <fa-icon [icon]="faCircleCheck"></fa-icon>
                  } @else {
                    <fa-icon [icon]="faCircleXmark"></fa-icon>
                  }
                </td>
                <td>
                  @if (d.commission?.cycle?.name) {
                    <span class="badge badge-outline h-auto rounded-xl">{{
                      d.commission?.cycle?.name
                    }}</span>
                  }
                </td>
                <td>
                  @if (d.status) {
                    @if (policyUpdateStatusMap[d.status]; as type) {
                      <span
                        class="badge gap-2 rounded-xl h-auto"
                        [class.bg-secondary]="type.color === 'secondary'"
                        [class.bg-accent]="type.color === 'accent'"
                        [class.bg-primary]="type.color === 'primary'"
                        [class.bg-red-500]="type.color === 'red'"
                        [class.bg-yellow-500]="type.color === 'yellow'"
                        [class.bg-green-500]="type.color === 'green'"
                        [class.bg-purple-500]="type.color === 'purple'"
                        [class.bg-gray-500]="type.color === 'gray'"
                        [class.text-white]="
                          type.color === 'primary' ||
                          type.color === 'green' ||
                          type.color === 'purple'
                        ">
                        <fa-icon *ngIf="type.icon" [icon]="type.icon"></fa-icon>
                        {{ type.title }}
                      </span>
                    }
                  }

                  @if (d.status === PolicyUpdateStatus.US_ERROR) {
                    <span>
                      {{ d.error?.message }}
                    </span>
                  }
                </td>
                <td>
                  <a class="tooltip" data-tip="View Policy Update">
                    <button
                      type="button"
                      class="btn btn-circle btn-ghost"
                      (click)="editPolicyUpdate(d)">
                      <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <p *ngIf="data && data.length === 0">No Policy Updates found</p>
    </div>
  </div>
</ng-container>

<!-- <app-policy-update-edit-modal
  #policyUpdatesEditModal
  (retryClicked)="retry($event)"></app-policy-update-edit-modal> -->
