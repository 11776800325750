import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PolicyUpdate } from 'src/app/shared/models/classes/policy-update';
import {
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faEye,
  faSave,
} from '@fortawesome/pro-regular-svg-icons';
import { UserInfo } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { carrierMap } from '../../../commission-statistics/commission-statistics/carrier';
import { FormsModule } from '@angular/forms';
import { DatePipe, JsonPipe, KeyValuePipe } from '@angular/common';
import { LoadingComponent } from '../../../ui-components/loading/loading.component';
import { planTypeMap } from 'src/app/shared/data/commissions';
import { ModalHistoryComponent } from '../../../ui-components/modal-history/modal-history.component';
import { Commission, Enrollment, UserType } from 'src/app/shared/models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';

@Component({
  selector: 'app-policy-update-edit-modal',
  standalone: true,
  imports: [
    DatePipe,
    FaIconComponent,
    FormsModule,
    KeyValuePipe,
    LoadingComponent,
    ModalHistoryComponent,
    JsonPipe,
  ],
  templateUrl: './policy-update-edit-modal.component.html',
  styleUrl: './policy-update-edit-modal.component.scss',
})
export class PolicyUpdateEditModalComponent implements AfterViewInit {
  @Input() openWith?: PolicyUpdate;

  @Output() retryClicked = new EventEmitter<PolicyUpdate>();
  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalHistoryComponent;

  faCircle = faCircle;
  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;
  faEye = faEye;
  faSave = faSave;

  policyUpdate?: PolicyUpdate;

  carrierMap = carrierMap;
  planTypeMap = planTypeMap;
  PolicyUpdate = PolicyUpdate;
  UserType = UserType;

  loading = false;

  showAdvanced = false;

  isDemo = environment.isDemo;
  production = environment.production;

  authUser?: UserInfo;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private modalContainerService: ModalContainerService
  ) {}

  ngAfterViewInit() {
    if (this.openWith) {
      this.open(this.openWith);
      this.cdr.detectChanges();
    }
  }

  open(policyUpdate: PolicyUpdate) {
    this.policyUpdate = policyUpdate;

    this.modal?.show();
  }

  retry(policyUpdate: PolicyUpdate) {
    this.retryClicked.emit(policyUpdate);
  }

  toggleShowAdvanced() {
    this.showAdvanced = !this.showAdvanced;
    this.cdr.detectChanges();
  }

  modalDismissed() {
    delete this.policyUpdate;
    this.modalDismissedFired.emit();
  }

  showCommission(commission: Commission) {
    console.log('show commission', commission);

    this.modalContainerService.open(ShowModalComponents.COMMISSION, {
      commission: commission,
      hideEdit: true,
    });
  }

  showEnrollment(enrollment: Enrollment) {
    console.log('show enrollment', enrollment);

    this.modalContainerService.open(ShowModalComponents.ENROLLMENT, {
      enrollment: enrollment,
    });
  }
}
