/* eslint-disable */
// @ts-ignore
import * as _m0 from "protobufjs/minimal";
import { Commission } from "./commissions";
import { Enrollment } from "./enrollment";
import { Struct } from "./google/protobuf/struct";
import { DateContainer, IdentifierContainer } from "./misc";

export const protobufPackage = "";

export enum PolicyUpdateStatus {
  US_NONE = 0,
  US_PENDING = 1,
  US_SUCCESS = 10,
  US_ERROR = 20,
  US_ERROR_MISSING_COMMISSION = 21,
  US_ERROR_MISSING_ENROLLMENT = 22,
  UNRECOGNIZED = -1,
}

export function policyUpdateStatusFromJSON(object: any): PolicyUpdateStatus {
  switch (object) {
    case 0:
    case "US_NONE":
      return PolicyUpdateStatus.US_NONE;
    case 1:
    case "US_PENDING":
      return PolicyUpdateStatus.US_PENDING;
    case 10:
    case "US_SUCCESS":
      return PolicyUpdateStatus.US_SUCCESS;
    case 20:
    case "US_ERROR":
      return PolicyUpdateStatus.US_ERROR;
    case 21:
    case "US_ERROR_MISSING_COMMISSION":
      return PolicyUpdateStatus.US_ERROR_MISSING_COMMISSION;
    case 22:
    case "US_ERROR_MISSING_ENROLLMENT":
      return PolicyUpdateStatus.US_ERROR_MISSING_ENROLLMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PolicyUpdateStatus.UNRECOGNIZED;
  }
}

export function policyUpdateStatusToJSON(object: PolicyUpdateStatus): string {
  switch (object) {
    case PolicyUpdateStatus.US_NONE:
      return "US_NONE";
    case PolicyUpdateStatus.US_PENDING:
      return "US_PENDING";
    case PolicyUpdateStatus.US_SUCCESS:
      return "US_SUCCESS";
    case PolicyUpdateStatus.US_ERROR:
      return "US_ERROR";
    case PolicyUpdateStatus.US_ERROR_MISSING_COMMISSION:
      return "US_ERROR_MISSING_COMMISSION";
    case PolicyUpdateStatus.US_ERROR_MISSING_ENROLLMENT:
      return "US_ERROR_MISSING_ENROLLMENT";
    case PolicyUpdateStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum UpdateActionType {
  UA_NONE = 0,
  UA_OVERWRITE = 1,
  UNRECOGNIZED = -1,
}

export function updateActionTypeFromJSON(object: any): UpdateActionType {
  switch (object) {
    case 0:
    case "UA_NONE":
      return UpdateActionType.UA_NONE;
    case 1:
    case "UA_OVERWRITE":
      return UpdateActionType.UA_OVERWRITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UpdateActionType.UNRECOGNIZED;
  }
}

export function updateActionTypeToJSON(object: UpdateActionType): string {
  switch (object) {
    case UpdateActionType.UA_NONE:
      return "UA_NONE";
    case UpdateActionType.UA_OVERWRITE:
      return "UA_OVERWRITE";
    case UpdateActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PolicyUpdate {
  id?: string | undefined;
  createdAt?: DateContainer | undefined;
  identifiers?: IdentifierContainer | undefined;
  commission?: Commission | undefined;
  enrollment?: Enrollment | undefined;
  actions?: UpdateActionContainer | undefined;
  status?: PolicyUpdateStatus | undefined;
  error?: PolicyUpdate_Error | undefined;
  channel?: PolicyUpdateChannel | undefined;
}

export interface PolicyUpdate_Error {
  code?: PolicyUpdate_Error_Code | undefined;
  message?: string | undefined;
  details?: { [key: string]: any } | undefined;
}

export enum PolicyUpdate_Error_Code {
  CE_UNKNOWN = 0,
  CE_INTERNAL = 1,
  CE_CREATE_ENROLLMENT = 2,
  UNRECOGNIZED = -1,
}

export function policyUpdate_Error_CodeFromJSON(object: any): PolicyUpdate_Error_Code {
  switch (object) {
    case 0:
    case "CE_UNKNOWN":
      return PolicyUpdate_Error_Code.CE_UNKNOWN;
    case 1:
    case "CE_INTERNAL":
      return PolicyUpdate_Error_Code.CE_INTERNAL;
    case 2:
    case "CE_CREATE_ENROLLMENT":
      return PolicyUpdate_Error_Code.CE_CREATE_ENROLLMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PolicyUpdate_Error_Code.UNRECOGNIZED;
  }
}

export function policyUpdate_Error_CodeToJSON(object: PolicyUpdate_Error_Code): string {
  switch (object) {
    case PolicyUpdate_Error_Code.CE_UNKNOWN:
      return "CE_UNKNOWN";
    case PolicyUpdate_Error_Code.CE_INTERNAL:
      return "CE_INTERNAL";
    case PolicyUpdate_Error_Code.CE_CREATE_ENROLLMENT:
      return "CE_CREATE_ENROLLMENT";
    case PolicyUpdate_Error_Code.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UpdateActionContainer {
  actions: UpdateAction[];
  options?: UpdateActionOptions | undefined;
}

export interface UpdateAction {
  key?: string | undefined;
  type?: UpdateActionType | undefined;
}

export interface UpdateActionOptions {
  createNewCommission?: boolean | undefined;
  createNewEnrollment?: boolean | undefined;
}

export interface PolicyUpdateChannel {
  uploadDate?: DateContainer | undefined;
  id?: string | undefined;
  name?: string | undefined;
  agencyId?: string | undefined;
}

function createBasePolicyUpdate(): PolicyUpdate {
  return {
    id: undefined,
    createdAt: undefined,
    identifiers: undefined,
    commission: undefined,
    enrollment: undefined,
    actions: undefined,
    status: undefined,
    error: undefined,
    channel: undefined,
  };
}

export const PolicyUpdate = {
  encode(message: PolicyUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      DateContainer.encode(message.createdAt, writer.uint32(18).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(26).fork()).ldelim();
    }
    if (message.commission !== undefined) {
      Commission.encode(message.commission, writer.uint32(34).fork()).ldelim();
    }
    if (message.enrollment !== undefined) {
      Enrollment.encode(message.enrollment, writer.uint32(42).fork()).ldelim();
    }
    if (message.actions !== undefined) {
      UpdateActionContainer.encode(message.actions, writer.uint32(50).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(56).int32(message.status);
    }
    if (message.error !== undefined) {
      PolicyUpdate_Error.encode(message.error, writer.uint32(66).fork()).ldelim();
    }
    if (message.channel !== undefined) {
      PolicyUpdateChannel.encode(message.channel, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = DateContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.commission = Commission.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.enrollment = Enrollment.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.actions = UpdateActionContainer.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.error = PolicyUpdate_Error.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.channel = PolicyUpdateChannel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyUpdate {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? DateContainer.fromJSON(object.createdAt) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      commission: isSet(object.commission) ? Commission.fromJSON(object.commission) : undefined,
      enrollment: isSet(object.enrollment) ? Enrollment.fromJSON(object.enrollment) : undefined,
      actions: isSet(object.actions) ? UpdateActionContainer.fromJSON(object.actions) : undefined,
      status: isSet(object.status) ? policyUpdateStatusFromJSON(object.status) : undefined,
      error: isSet(object.error) ? PolicyUpdate_Error.fromJSON(object.error) : undefined,
      channel: isSet(object.channel) ? PolicyUpdateChannel.fromJSON(object.channel) : undefined,
    };
  },

  toJSON(message: PolicyUpdate): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt ? DateContainer.toJSON(message.createdAt) : undefined);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.commission !== undefined &&
      (obj.commission = message.commission ? Commission.toJSON(message.commission) : undefined);
    message.enrollment !== undefined &&
      (obj.enrollment = message.enrollment ? Enrollment.toJSON(message.enrollment) : undefined);
    message.actions !== undefined &&
      (obj.actions = message.actions ? UpdateActionContainer.toJSON(message.actions) : undefined);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? policyUpdateStatusToJSON(message.status) : undefined);
    message.error !== undefined && (obj.error = message.error ? PolicyUpdate_Error.toJSON(message.error) : undefined);
    message.channel !== undefined &&
      (obj.channel = message.channel ? PolicyUpdateChannel.toJSON(message.channel) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyUpdate>, I>>(base?: I): PolicyUpdate {
    return PolicyUpdate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyUpdate>, I>>(object: I): PolicyUpdate {
    const message = createBasePolicyUpdate();
    message.id = object.id ?? undefined;
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? DateContainer.fromPartial(object.createdAt)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.commission = (object.commission !== undefined && object.commission !== null)
      ? Commission.fromPartial(object.commission)
      : undefined;
    message.enrollment = (object.enrollment !== undefined && object.enrollment !== null)
      ? Enrollment.fromPartial(object.enrollment)
      : undefined;
    message.actions = (object.actions !== undefined && object.actions !== null)
      ? UpdateActionContainer.fromPartial(object.actions)
      : undefined;
    message.status = object.status ?? undefined;
    message.error = (object.error !== undefined && object.error !== null)
      ? PolicyUpdate_Error.fromPartial(object.error)
      : undefined;
    message.channel = (object.channel !== undefined && object.channel !== null)
      ? PolicyUpdateChannel.fromPartial(object.channel)
      : undefined;
    return message;
  },
};

function createBasePolicyUpdate_Error(): PolicyUpdate_Error {
  return { code: undefined, message: undefined, details: undefined };
}

export const PolicyUpdate_Error = {
  encode(message: PolicyUpdate_Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== undefined) {
      writer.uint32(16).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(10).string(message.message);
    }
    if (message.details !== undefined) {
      Struct.encode(Struct.wrap(message.details), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyUpdate_Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyUpdate_Error();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyUpdate_Error {
    return {
      code: isSet(object.code) ? policyUpdate_Error_CodeFromJSON(object.code) : undefined,
      message: isSet(object.message) ? String(object.message) : undefined,
      details: isObject(object.details) ? object.details : undefined,
    };
  },

  toJSON(message: PolicyUpdate_Error): unknown {
    const obj: any = {};
    message.code !== undefined &&
      (obj.code = message.code !== undefined ? policyUpdate_Error_CodeToJSON(message.code) : undefined);
    message.message !== undefined && (obj.message = message.message);
    message.details !== undefined && (obj.details = message.details);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyUpdate_Error>, I>>(base?: I): PolicyUpdate_Error {
    return PolicyUpdate_Error.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyUpdate_Error>, I>>(object: I): PolicyUpdate_Error {
    const message = createBasePolicyUpdate_Error();
    message.code = object.code ?? undefined;
    message.message = object.message ?? undefined;
    message.details = object.details ?? undefined;
    return message;
  },
};

function createBaseUpdateActionContainer(): UpdateActionContainer {
  return { actions: [], options: undefined };
}

export const UpdateActionContainer = {
  encode(message: UpdateActionContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.actions) {
      UpdateAction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.options !== undefined) {
      UpdateActionOptions.encode(message.options, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateActionContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateActionContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actions.push(UpdateAction.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.options = UpdateActionOptions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateActionContainer {
    return {
      actions: Array.isArray(object?.actions) ? object.actions.map((e: any) => UpdateAction.fromJSON(e)) : [],
      options: isSet(object.options) ? UpdateActionOptions.fromJSON(object.options) : undefined,
    };
  },

  toJSON(message: UpdateActionContainer): unknown {
    const obj: any = {};
    if (message.actions) {
      obj.actions = message.actions.map((e) => e ? UpdateAction.toJSON(e) : undefined);
    } else {
      obj.actions = [];
    }
    message.options !== undefined &&
      (obj.options = message.options ? UpdateActionOptions.toJSON(message.options) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateActionContainer>, I>>(base?: I): UpdateActionContainer {
    return UpdateActionContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateActionContainer>, I>>(object: I): UpdateActionContainer {
    const message = createBaseUpdateActionContainer();
    message.actions = object.actions?.map((e) => UpdateAction.fromPartial(e)) || [];
    message.options = (object.options !== undefined && object.options !== null)
      ? UpdateActionOptions.fromPartial(object.options)
      : undefined;
    return message;
  },
};

function createBaseUpdateAction(): UpdateAction {
  return { key: undefined, type: undefined };
}

export const UpdateAction = {
  encode(message: UpdateAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined) {
      writer.uint32(10).string(message.key);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAction {
    return {
      key: isSet(object.key) ? String(object.key) : undefined,
      type: isSet(object.type) ? updateActionTypeFromJSON(object.type) : undefined,
    };
  },

  toJSON(message: UpdateAction): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? updateActionTypeToJSON(message.type) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAction>, I>>(base?: I): UpdateAction {
    return UpdateAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateAction>, I>>(object: I): UpdateAction {
    const message = createBaseUpdateAction();
    message.key = object.key ?? undefined;
    message.type = object.type ?? undefined;
    return message;
  },
};

function createBaseUpdateActionOptions(): UpdateActionOptions {
  return { createNewCommission: undefined, createNewEnrollment: undefined };
}

export const UpdateActionOptions = {
  encode(message: UpdateActionOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createNewCommission !== undefined) {
      writer.uint32(8).bool(message.createNewCommission);
    }
    if (message.createNewEnrollment !== undefined) {
      writer.uint32(16).bool(message.createNewEnrollment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateActionOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateActionOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.createNewCommission = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createNewEnrollment = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateActionOptions {
    return {
      createNewCommission: isSet(object.createNewCommission) ? Boolean(object.createNewCommission) : undefined,
      createNewEnrollment: isSet(object.createNewEnrollment) ? Boolean(object.createNewEnrollment) : undefined,
    };
  },

  toJSON(message: UpdateActionOptions): unknown {
    const obj: any = {};
    message.createNewCommission !== undefined && (obj.createNewCommission = message.createNewCommission);
    message.createNewEnrollment !== undefined && (obj.createNewEnrollment = message.createNewEnrollment);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateActionOptions>, I>>(base?: I): UpdateActionOptions {
    return UpdateActionOptions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateActionOptions>, I>>(object: I): UpdateActionOptions {
    const message = createBaseUpdateActionOptions();
    message.createNewCommission = object.createNewCommission ?? undefined;
    message.createNewEnrollment = object.createNewEnrollment ?? undefined;
    return message;
  },
};

function createBasePolicyUpdateChannel(): PolicyUpdateChannel {
  return { uploadDate: undefined, id: undefined, name: undefined, agencyId: undefined };
}

export const PolicyUpdateChannel = {
  encode(message: PolicyUpdateChannel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uploadDate !== undefined) {
      DateContainer.encode(message.uploadDate, writer.uint32(10).fork()).ldelim();
    }
    if (message.id !== undefined) {
      writer.uint32(18).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(34).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyUpdateChannel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyUpdateChannel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uploadDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyUpdateChannel {
    return {
      uploadDate: isSet(object.uploadDate) ? DateContainer.fromJSON(object.uploadDate) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: PolicyUpdateChannel): unknown {
    const obj: any = {};
    message.uploadDate !== undefined &&
      (obj.uploadDate = message.uploadDate ? DateContainer.toJSON(message.uploadDate) : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyUpdateChannel>, I>>(base?: I): PolicyUpdateChannel {
    return PolicyUpdateChannel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyUpdateChannel>, I>>(object: I): PolicyUpdateChannel {
    const message = createBasePolicyUpdateChannel();
    message.uploadDate = (object.uploadDate !== undefined && object.uploadDate !== null)
      ? DateContainer.fromPartial(object.uploadDate)
      : undefined;
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
