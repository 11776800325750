import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { IdentifierContainer } from '../../models/classes/misc';

@Injectable({
  providedIn: 'root',
})
export class IdentifiedGroupService {
  constructor(private firestore: Firestore) {}

  loadSingle = (
    userId: string,
    identifiedGroupId: string
  ): Promise<IdentifierContainer | null> =>
    new Promise(async resolve => {
      console.log('loadSingle identifiedGroup', { userId, identifiedGroupId });

      const docRes = await getDoc(
        doc(
          this.firestore,
          'agencies',
          userId,
          'identifiedGroups',
          identifiedGroupId
        )
      );

      if (!docRes.exists()) {
        return resolve(null);
      }

      resolve(
        IdentifierContainer.fromJSON({
          ...docRes.data(),
          id: docRes.id,
        })
      );
    });
}
