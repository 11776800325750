import { Injectable } from '@angular/core';
import { ElasticService } from '../elastic.service';
import { environment } from 'src/environments/environment';
import { PolicyUpdate } from '../../models/classes/policy-update';

@Injectable({
  providedIn: 'root',
})
export class PolicyUpdateHistoryService {
  constructor(private elasticService: ElasticService) {}

  loadPolicyUpdateHistory = async (identifierGroupId: string) => {
    const res = await this.elasticService.query(
      'agencies_policy_updates',
      {
        query: {
          match: {
            'identifiers.identifierGroupId': identifierGroupId,
          },
        },
        sort: {
          'createdAt.timestamp': {
            order: 'desc',
          },
        },
      },
      environment.elasticPrefixBase
    );

    console.log('loadPolicyUpdateHistory', res);

    const results = res?.hits?.hits?.map((hit: any) =>
      PolicyUpdate.fromJSON({ ...hit._source, id: hit._id })
    );

    return results;
  };
}
