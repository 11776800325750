import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { takeWhile } from 'rxjs';
import { IdentifierContainer } from 'src/app/shared/models/classes/misc';
import { AuthService } from 'src/app/shared/services/auth.service';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';
import { IdentifiedGroupService } from 'src/app/shared/services/policyUpdate/identified-group.service';

@Component({
  selector: 'app-identifier-group-overview',
  standalone: true,
  imports: [FaIconComponent],
  templateUrl: './identifier-group-overview.component.html',
  styleUrl: './identifier-group-overview.component.scss',
})
export class IdentifierGroupOverviewComponent {
  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;
  faEye = faEye;

  @Input() userId?: string;
  @Input() identifierGroupId?: string;
  identifier?: IdentifierContainer;

  loading = true;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private identifiedGroupService: IdentifiedGroupService,
    private modalContainerService: ModalContainerService
  ) {
    this.cdr.detach();
  }

  ngOnInit() {
    if (this.userId) {
      this.loadIdentifier();
    } else {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id))
        .subscribe(u => {
          if (u?.id) {
            this.userId = u.id;
          }
          this.loadIdentifier();
        });
    }
  }
  loadIdentifier() {
    console.log('load identifier', this.userId, this.identifierGroupId);
    this.loading = true;
    if (this.userId && this.identifierGroupId) {
      this.identifiedGroupService
        .loadSingle(this.userId, this.identifierGroupId)
        .then(res => {
          this.loading = false;
          console.info('identifier', res);
          if (res) {
            this.identifier = res;
          }
          this.cdr.detectChanges();
        });
    }
  }

  showCommissions() {
    console.log('show commission');

    this.modalContainerService.open(ShowModalComponents.PAYOUT_OVERVIEW, {
      identifierGroupId: this.identifierGroupId,
      userId: this.userId,
    });
  }

  showEnrollment(enrollmentId: string) {
    console.log('show enrollment', enrollmentId);

    this.modalContainerService.open(ShowModalComponents.ENROLLMENT, {
      enrollmentId: enrollmentId,
    });
  }
}
