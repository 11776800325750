@if (loading) {
  <app-loading></app-loading>
} @else {
  <!-- <app-policy-update-table [data$]="policyUpdate$"> </app-policy-update-table> -->

  @if (policyUpdates; as state) {
    @if (state.policyUpdates; as data) {
      <!-- <div *ngIf="agents.error">
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
          <h3 class="my-4">An error occurred</h3>
          <p>{{ agents.error }}</p>
        </div> -->

      <div *ngIf="data && data.length > 0">
        <table class="table table-sm table-pin-cols">
          <thead>
            <tr>
              <td>Created at</td>
              <td>Status</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let d of data">
              <tr>
                <th>
                  {{ d.createdAt?.timestamp | date }}
                </th>
                <td>
                  @if (d.status) {
                    @if (policyUpdateStatusMap[d.status]; as type) {
                      <span
                        class="badge gap-2 rounded-xl h-auto"
                        [class.bg-secondary]="type.color === 'secondary'"
                        [class.bg-accent]="type.color === 'accent'"
                        [class.bg-primary]="type.color === 'primary'"
                        [class.bg-red-500]="type.color === 'red'"
                        [class.bg-yellow-500]="type.color === 'yellow'"
                        [class.bg-green-500]="type.color === 'green'"
                        [class.bg-purple-500]="type.color === 'purple'"
                        [class.bg-gray-500]="type.color === 'gray'"
                        [class.text-white]="
                          type.color === 'primary' ||
                          type.color === 'green' ||
                          type.color === 'purple'
                        ">
                        <fa-icon *ngIf="type.icon" [icon]="type.icon"></fa-icon>
                        {{ type.title }}
                      </span>
                    }
                  }

                  @if (d.status === PolicyUpdateStatus.US_ERROR) {
                    <span>
                      {{ d.error?.message }}
                    </span>
                  }
                </td>
                <td>
                  <a class="tooltip" data-tip="View Policy Update">
                    <button
                      type="button"
                      class="btn btn-circle btn-ghost"
                      (click)="editPolicyUpdate(d)">
                      <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <p *ngIf="data && data.length === 0">No Policy Updates found</p>
    }
  }
}
