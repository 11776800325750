import { Injectable } from '@angular/core';
import {
  PolicyUpdate,
  PolicyUpdateStatus,
  policyUpdateStatusToJSON,
} from '../models/classes/policy-update';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from '@angular/fire/firestore';
import { CookieService } from './cookie.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyUpdateService {
  demoData: any;
  replacementOldCarrierName?: string;
  replacementNewCarrierName?: string;

  constructor(
    private cookieService: CookieService,
    private firestore: Firestore,
    private functions: Functions
  ) {
    if (environment.isDemo || !environment.production) {
      const item = this.cookieService.get('demo-pass');
      try {
        this.demoData = item ? JSON.parse(item) : null;
      } catch (e) {
        this.demoData = null;
      }
    }
  }

  load = (
    userId: string,
    filter: any,
    sizeCount = 20,
    fromDoc?: QueryDocumentSnapshot<DocumentData, DocumentData>
  ): Promise<any> =>
    new Promise(async resolve => {
      console.log('Filter', { userId, filter, fromDoc, sizeCount });

      let q = query(
        collection(this.firestore, 'agencies', userId, 'policyUpdates')
      );

      if (filter.status) {
        q = query(
          q,
          where('status', '==', policyUpdateStatusToJSON(filter.status))
        );
      }

      if (filter.term) {
        q = query(
          q,
          where('identifiers.bySource.policyId', '>=', filter.term || ''),
          where('identifiers.bySource.policyId', '<=', filter.term + '\uf8ff')
        );
      }

      q = query(q, orderBy('createdAt.timestamp', 'desc'));

      if (fromDoc) {
        const fromDocRef = await getDoc(
          doc(this.firestore, 'agencies', userId, 'policyUpdates', fromDoc.id)
        );
        console.info('fromDoc', fromDoc.id);
        q = query(q, startAfter(fromDocRef));
      }

      if (sizeCount) {
        q = query(q, limit(sizeCount));
      }

      getDocs(q).then(res => {
        const policyUpdates: PolicyUpdate[] = [];

        console.log('docs', res.docs.length);
        for (const doc of res.docs) {
          policyUpdates.push(
            PolicyUpdate.fromJSON({
              ...doc.data(),
              id: doc.id,
            })
          );
        }

        let last = policyUpdates[policyUpdates.length - 1];

        resolve({ policyUpdates, last });
      });
    });

  retry(data: { agencyId: string; status: PolicyUpdateStatus }) {
    return httpsCallable(
      this.functions,
      'policyUpdatesRetry'
    )({
      agencyId: data.agencyId,
      policyUpdateStatus: policyUpdateStatusToJSON(data.status),
    }).then(res => {
      console.log('retry started', res);

      return res;
    });
  }

  retryPolicyUpdate(policyUpdate: PolicyUpdate) {
    httpsCallable(
      this.functions,
      'policyUpdatesRetry'
    )({
      agencyId: policyUpdate.commission?.agency?.id,
      policyUpdateId: policyUpdate.id,
    }).then(res => {
      console.log('retry started', res);
    });
  }

  // updateIdentifiedResult = (
  //   userId: string,
  //   commission: PolicyUpdate,
  //   result: PolicyUpdate_IdentifiedResult
  // ) =>
  //   new Promise((resolve, reject) => {
  //     if (!commission.id) {
  //       return;
  //     }
  //     const docRef = doc(
  //       this.firestore,
  //       'agencies',
  //       userId,
  //       'commissions',
  //       commission.id
  //     );

  //     const toUpdate = {
  //       identifiedResult: PolicyUpdate_IdentifiedResult.toJSON(result),
  //       status: commissionStatusToJSON(PolicyUpdateStatus.CS_PENDING),
  //     };

  //     setDoc(docRef, toUpdate, { merge: true })
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
}
