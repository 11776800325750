import {
  IconDefinition,
  faCheckCircle,
  faHourglass,
  faMoneyBill,
  faSignature,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { PolicyUpdateStatus } from '../models/classes/policy-update';

export const policyUpdateStatusMap: {
  [key: number]: { title: string; icon: IconDefinition; color: string };
} = {
  [PolicyUpdateStatus.US_ERROR]: {
    title: 'Error',
    icon: faTimesCircle,
    color: 'red',
  },

  [PolicyUpdateStatus.US_ERROR_MISSING_COMMISSION]: {
    title: 'Missing Commission',
    icon: faMoneyBill,
    color: 'red',
  },
  [PolicyUpdateStatus.US_ERROR_MISSING_ENROLLMENT]: {
    title: 'Missing Enrollment',
    icon: faSignature,
    color: 'red',
  },
  [PolicyUpdateStatus.US_PENDING]: {
    title: 'Pending',
    icon: faHourglass,
    color: 'yellow',
  },
  [PolicyUpdateStatus.US_SUCCESS]: {
    title: 'Success',
    icon: faCheckCircle,
    color: 'green',
  },
};
