@if (showModal) {
  <div
    class="fixed z-[100] top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md"
    (click)="hide()"></div>
  <div
    class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] max-h-full pt-4 overflow-y-auto w-11/12 grid gap-4"
    [class.lg:grid-cols-4]="identifierGroupId"
    [class.md:grid-cols-3]="identifierGroupId">
    <div
      [class.lg:col-span-3]="identifierGroupId"
      [class.md:col-span-2]="identifierGroupId">
      <div class="card bg-white relative">
        <div class="card-body">
          @if (showContent) {
            <ng-content></ng-content>
          }
          @if (loading) {
            <div class="loading loading-dots"></div>
          }
        </div>
      </div>
    </div>
    @if (identifierGroupId) {
      <div class="">
        <div class="card bg-white relative">
          <div class="card-body">
            <div class="card-title">
              <fa-icon [icon]="faClock"> </fa-icon>
              History
            </div>

            @if (allowHide) {
              <button
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                type="button"
                (click)="hide()">
                <fa-icon [icon]="faTimes"></fa-icon>
              </button>
            }

            <app-identifier-group-overview
              [userId]="userId"
              [identifierGroupId]="
                identifierGroupId
              "></app-identifier-group-overview>
            <app-history [identifierGroupId]="identifierGroupId"></app-history>
          </div>
        </div>
      </div>
    }
  </div>
}
