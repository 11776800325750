import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faClock, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuid } from 'uuid';
import { HistoryComponent } from './history/history.component';
import { PolicyUpdateTableComponent } from '../../policy-update/policy-update-table/policy-update-table.component';
import { LoadingComponent } from '../loading/loading.component';
import { IdentifierGroupOverviewComponent } from './identifier-group-overview/identifier-group-overview.component';

@Component({
  selector: 'app-modal-history',
  standalone: true,
  imports: [
    FaIconComponent,
    HistoryComponent,
    IdentifierGroupOverviewComponent,
    LoadingComponent,
    PolicyUpdateTableComponent,
  ],
  templateUrl: './modal-history.component.html',
  styleUrl: './modal-history.component.scss',
})
export class ModalHistoryComponent {
  faClock = faClock;
  faTimes = faTimes;

  id = uuid();
  @Input() loading = false;
  @Input() allowHide = true;
  @Input() wide = false;

  @Input() isOpen = false;
  @Input() allowClose = true;
  @Input() overflowVisible = false;

  @Input() userId?: string;
  @Input() identifierGroupId?: string;

  @Output() dismissed = new EventEmitter<void>();

  showContent = false;
  showModal = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this.isOpen) {
      console.log('open');
      this.show();
    }
  }

  show() {
    this.showContent = true;

    this.showModal = true;
    this.cdr.detectChanges();
  }

  hide() {
    if (this.allowClose) {
      this.showContent = false;
      this.dismissed.emit();

      this.showModal = false;
      this.cdr.detectChanges();
    }
  }
}
