import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { LoadingComponent } from '../../loading/loading.component';
import { PolicyUpdateHistoryService } from 'src/app/shared/services/policyUpdate/policy-update-history.service';
import {
  PolicyUpdate,
  PolicyUpdateStatus,
} from 'src/app/shared/models/classes/policy-update';
import { ReplaySubject } from 'rxjs';
import { PolicyUpdateTableComponent } from '../../../policy-update/policy-update-table/policy-update-table.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { policyUpdateStatusMap } from 'src/app/shared/data/policy-update';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    DatePipe,
    FaIconComponent,
    LoadingComponent,
    NgFor,
    NgIf,
    PolicyUpdateTableComponent,
  ],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss',
})
export class HistoryComponent {
  @Input() identifierGroupId?: string;

  faEye = faEye;

  policyUpdateStatusMap = policyUpdateStatusMap;
  PolicyUpdateStatus = PolicyUpdateStatus;

  loading = false;
  policyUpdates?: {
    loading: false;
    policyUpdates: PolicyUpdate[] | null;
    policyUpdate: PolicyUpdate | null;
    error: null;
  };
  policyUpdate$: ReplaySubject<{
    loading: false;
    policyUpdates: PolicyUpdate[] | null;
    policyUpdate: PolicyUpdate | null;
    error: null;
  }> = new ReplaySubject(1);

  constructor(
    private cdr: ChangeDetectorRef,
    private modalContainerService: ModalContainerService,
    private policyUpdateHistoryService: PolicyUpdateHistoryService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['identifierGroupId']) {
      if (
        changes['identifierGroupId'].currentValue !==
        changes['identifierGroupId'].previousValue
      ) {
        this.loading = true;
        this.cdr.detectChanges();

        this.policyUpdateHistoryService
          .loadPolicyUpdateHistory(changes['identifierGroupId'].currentValue)
          .then(res => {
            console.log('loadPolicyUpdateHistory', res);
            this.policyUpdates = {
              loading: false,
              policyUpdates: res,
              policyUpdate: null,
              error: null,
            };

            if (this.policyUpdate$) {
              this.policyUpdate$.next({
                loading: false,
                policyUpdates: res,
                policyUpdate: null,
                error: null,
              });
            }
            this.loading = false;
            this.cdr.detectChanges();
          });
      }
    }
  }

  editPolicyUpdate(policyUpdate: PolicyUpdate) {
    console.log('editPolicyUpdate', policyUpdate);
    // this.policyUpdatesEditModal?.open(policyUpdate);
    this.modalContainerService.open(
      ShowModalComponents.POLICY_UPDATE,
      policyUpdate
    );
  }
}
