@if (identifierGroupId && userId) {
  @if (identifier) {
    <div class="grid gap-2">
      <div class="card card-compact bg-primary text-white">
        <div class="card-body">
          <h4>
            @if (
              identifier.internalIds &&
              identifier.internalIds.commissionIds &&
              identifier.internalIds.commissionIds.length > 0
            ) {
              <fa-icon [icon]="faCircleCheck"></fa-icon>
            } @else {
              <fa-icon [icon]="faCircleXmark"></fa-icon>
            }

            @if (
              identifier.internalIds &&
              identifier.internalIds.commissionIds &&
              identifier.internalIds.commissionIds.length === 1
            ) {
              1 Commission
            } @else {
              {{
                identifier.internalIds?.commissionIds?.length ?? 0
              }}
              Commissions
            }
          </h4>
          @if (
            identifier.internalIds &&
            identifier.internalIds.commissionIds &&
            identifier.internalIds.commissionIds.length > 0
          ) {
            <div class="card-actions">
              <button
                class="btn bg-white text-primary btn-sm"
                type="button"
                (click)="showCommissions()">
                <fa-icon [icon]="faEye"></fa-icon>
                Show
              </button>
            </div>
          }
        </div>
      </div>
      <div class="card card-compact bg-primary text-white">
        <div class="card-body">
          <h4>
            @if (identifier.internalIds?.enrollmentId) {
              <fa-icon [icon]="faCircleCheck"></fa-icon>
            } @else {
              <fa-icon [icon]="faCircleXmark"></fa-icon>
            }
            Enrollment
          </h4>
          @if (identifier.internalIds && identifier.internalIds.enrollmentId) {
            <div class="card-actions">
              <button
                class="btn bg-white text-primary btn-sm"
                type="button"
                (click)="showEnrollment(identifier.internalIds.enrollmentId)">
                <fa-icon [icon]="faEye"></fa-icon>
                Show
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  }
  @if (loading) {
    <div class="card skeleton w-full h-32"></div>
  }
}
